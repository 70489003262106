.conti {
    /* display: inline-block;
    vertical-align: bottom; */
    opacity: 0;
    overflow: hidden;
    animation: slow 1s ease forwards;
}

@keyframes slow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.dropdown_btn {
    color: black !important;
    background-color: transparent !important;
    border:none;
    border-radius: 5px !important;
    height: 50px !important;
    width: 32.5vw;
    min-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* .dropdown_btn:active {
    border: 1px solid #1C98AF !important;
} */

.dropdown_menu {
    width: 32.5vw;
    min-width: 225px;
}

.dropdown-item.active {
    background-color: #1C98AF !important;
}

.dropdown-item:active {
    background-color: #21b5cf !important;
    color: white !important;
}

.dropdown-item:active a {
    background-color: #21b5cf !important;
    color: white !important;
}

.dropdown-item.active a {
    color: white !important;
}

.dropdown-item a {
    color: black;
}

.dropdown-menu {
    max-height: 35vh;
    overflow-y: auto;
    padding-top: 0px;
}

.dropdown-item {
    white-space: unset;
    padding: 0;
}

.dropdown-item a {
    padding: 10px 10px;
}

.dropdown-item:nth-child(even) {
    background-color: #f4f9ff;
    border-top: 0.5px solid #e1e4e7;
    border-bottom: 0.5px solid #e1e4e7;
}

::-webkit-scrollbar {
    width: 1em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(81, 50, 139, 0.3);
    background-color: #F5F5F5;
    border-bottom-right-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #71ccdf;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    /* outline: 1px solid #116A7B; */
    /* outline-style: dashed; */
}

@media (max-width: 960px) {
    .dropdown_btn {
        width: 100%;
    }
    .dropdown_menu {
        width: 100%;
    }
    .content {
        padding-top: 5rem !important;
    }
    
}

@media (max-width:1100px) {
    .dropdown_btn{
        border: 1px solid black !important;
    }
    
}
