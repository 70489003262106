@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
    height: 100%;
}

#root {
    height: 100vh;
}

.con {
    height: 66%;
}

a {
    text-decoration: none !important;
}


/* .katex-html {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word;
} */


/* * {
    text-align: center;
} */