/* * {
    width: 0;
    padding: 0;
  }
  
  body {
    width: 100vw;
    min-height: 100vh;
    background: #86aace;
  
    overflow-x: hidden;
  } */

.gradient-decent {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.gradient-decent>* {
    margin: 10px auto;
}

.gradient-decent h2 {
    width: 60%;
    font-size: 2.1rem;
    text-align: center;
    /* text-shadow: 2px 2px 10px #000; */
}

.gradient-decent .run {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gradient-decent .run .run-btn {
    padding: 10px 15px;
    /* width: max-content; */
    width: 15vw;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    cursor: pointer;
    background: #007900;
    transition: 0.1s;
}

.gradient-decent .run .run-btn:hover {
    transform: scale(0.95);
}

.gradient-decent .run .run-btn:active {
    transform: scale(0.8);
}

.gradient-decent .run .run-btn.running {
    background: #f00;
}

.gradient-decent .control {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gradient-decent .control span {
    margin-bottom: -20px;
    width: max-content;
    font-size: 1.2rem;
    font-weight: 700;
    transition: 0.3s;
}

.gradient-decent .control span.reset {
    cursor: pointer;
    color: #00f;
}

.gradient-decent .control span.reset:hover {
    color: orangered;
}

.gradient-decent .control span.reset:active {
    transform: scale(0.8);
}

.graph {
    width: 60%;
    border: 3px solid #000;
    aspect-ratio: 1;
    background: #86aace;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.graph>* {
    position: absolute;
}

.graph .point {
    width: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: rgb(19, 19, 141);
    transform: translate(-50%, -50%);
}

.graph .line {
    height: 2px;
    background: #000;
    transform-origin: center;
    transition: 0.5s;
}

.gradient-decent .fx {
    width: 60%;
    float: left;
    font-size: 1.7rem;
    text-align: center;
}

table {
    border-collapse: collapse;
    width: 100%;
}

tr th {
    font-size: 18px;
    padding: 12px;
    border: 1px solid #eeeeee;
    text-align: left;
    background-color: rgba(217, 221, 146, 0.2);
}

tr td {
    border: 1px solid #eeeeee;
    text-align: left;
}

.InputTable input {
    font-size: 16px;
    background-color: transparent;
    border: none;
    width: 91%;
    padding: 12px 12px;
    font-family: 'Poppins', sans-serif;
}

.InputTable input:hover {
    background-color: #fffaf3;
}

.InputTable input:focus {
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
}