.Header {
    display: block;
    font-family: roboto_mono;
    color: #116A7B;
    font-size: 40px;
    letter-spacing: 2px;
}

.normal {
    display: block;
    font-family: inter;
    color: #666666;
    font-size: 20px;
    padding: 2% 0;
}

.Number_input,
.Number_input:hover,
.Number_input:focus-visible {
    width: 25%;
    border: none !important;
    outline: none !important;
    text-align: center;
}

.Matrix_Input,
.Matrix_Input:focus-visible {
    outline: none !important;
    text-align: center !important;
}


/* .MinputCont {
    max-width: 55vw;
} */

.Controlbtn {
    width: 25% !important;
    border-radius: 100px !important;
    background-color: transparent !important;
    border: none !important;
    padding: 5px 2px !important;
}

.NBInputC {
    min-width: 100px;
    width: 10vw;
    border: 2px solid black;
    border-radius: 50px;
    padding: 2px;
}

.content {
    /* padding: 25vh; */
    padding-top: 12.5vmax;
    padding-bottom: 10vh;
    height: fit-content;
}